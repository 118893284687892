@import "@angular/material/prebuilt-themes/indigo-pink.css";
/* You can add global styles to this file, and also import other style files */
.margin-left-5 {
  margin-left: 5px !important;
}
.margin-right-5 {
  margin-right: 5px !important;
}
.margin-top-5 {
  margin-top: 5px !important;
}
.margin-top-15 {
  margin-top: 15px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-5 {
  margin-bottom: 5px !important;
}
.margin-bottom-15 {
  margin-bottom: 15px !important;
}
.padding-left-5 {
  padding-left: 5px !important;
}
.padding-right-5 {
  padding-right: 5px !important;
}
.padding-top-5 {
  padding-top: 5px !important;
}
.padding-bottom-5 {
  padding-bottom: 5px !important;
}
.w-10px {
  width: 10px !important;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-10 {
  width: 10%;
}
.width-percent-15 {
  width: 15%;
}
.width-percent-20 {
  width: 20%;
}
.width-percent-30 {
  width: 30%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-85 {
  width: 85%;
}
.width-percent-100 {
  width: 100%;
}
/* Sweet alert */
.swal2-title {
  display: inline-block !important;
}
body.swal2-height-auto {
  height: inherit !important;
}
.swal2-popup.swal2-toast {
  padding: 12px 10px 12px 0px !important;
}
@keyframes glowing {
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
}
.glowing {
  animation: glowing 2s infinite;
}
account message-bar .message-container {
  width: 380px;
  margin-left: 60px;
}
account {
  background: #001944;
  height: 100%;
}
@media (max-width: 768px) {
  .m-login.m-login--1 .m-login__aside .m-login__wrapper {
    background: #001944 !important;
    color: #fff !important;
    box-shadow: 1px 1px 1px 2px #001944;
  }
}
@media (max-width: 1500px) {
  .m-login.m-login--1 .m-login__aside .m-login__wrapper {
    background: #001944 !important;
    color: #fff !important;
    box-shadow: 1px 1px 1px 2px #001944;
  }
}
.m-login.m-login--1 .m-login__wrapper {
  background: #001944;
  width: 37rem !important;
}
.m-login__aside {
  background-color: #001944;
  color: #fff;
}
.m-login__logo a img.dac6_logo {
  filter: none;
}
.m-login__logo a img.kpmg_logo {
  filter: brightness(0) invert(1);
}
.root-background {
  background: #001944 !important;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.login-text {
  color: #fff !important;
}
tenant-change .modal .modal-content .modal-body {
  background: #001944 !important;
  font-size: 14px !important;
}
tenant-change .modal .modal-content .modal-footer {
  background: #001944 !important;
  border-top: 1px solid #032c72 !important;
}
tenant-change .modal .modal-content .modal-header {
  background: #00295f !important;
  border-bottom: none !important;
  border-radius: 0px !important;
}
.center {
  text-align: center;
}
.m-login__form-action .login {
  background-color: #38a3f7 !important;
  border-color: #38a3f7 !important;
  width: 100px !important;
  padding: 10px !important;
}
.m-login__form-action .login:hover {
  background-color: #38a3f7 !important;
  border-color: #38a3f7 !important;
  width: 100px !important;
  padding: 10px !important;
}
.check_size {
  height: 20px !important;
  width: 20px !important;
}
.check_centered {
  align-self: center !important;
}
tenantChangeModal .close-button {
  background-color: #001944 !important;
  color: #38a3f7 !important;
}
tenantChangeModal .close-button:hover {
  background-color: #38a3f7 !important;
  color: #FFFFFF !important;
}
tenantChangeModal .close-button:active {
  background-color: #38a3f7 !important;
  color: #FFFFFF !important;
}
tenantChangeModal .close-button:focus {
  background-color: #38a3f7 !important;
  color: #FFFFFF !important;
}
tenantChangeModal .save-button {
  background-color: #38a3f7 !important;
  border-color: #38a3f7 !important;
  width: 100px !important;
  width: auto !important;
}
tenantChangeModal .save-button:hover {
  background-color: #38a3f7 !important;
  border-color: #38a3f7 !important;
  width: 100px !important;
  width: auto !important;
}
.forget-container {
  margin-top: -30px !important;
}
.forget-container a {
  font-size: 14px;
  color: #FFFFFF;
}
.m-login.m-login--1 .m-login__wrapper .m-login__form {
  margin-top: 1rem !important;
}
.p-chips,
.p-chips-multiple-container {
  width: 100% !important;
}
.p-tag-value {
  line-height: 1;
  font-size: 12px;
}
.height-28-px {
  height: 28px;
}
.max-width-percent-55 {
  max-width: 55% !important;
}
.max-width-percent-65 {
  max-width: 65% !important;
}
.max-width-percent-75 {
  max-width: 75% !important;
}
.margin-left-75-px {
  margin-left: 75px !important;
}
.modal-xl {
  max-width: 100rem;
}
.max-width-px-110 {
  max-width: 110px !important;
}
.resize-none {
  resize: none !important;
}
.break-word-break-word {
  word-break: break-word !important;
}
.help-link {
  text-decoration: underline !important;
}
.height-percent-75 {
  height: 75% !important;
}
.cursor-default {
  cursor: default !important;
}
.margin-top-32 {
  margin-top: 32px !important;
}
.p-dialog {
  min-width: 200px ;
}
.p-dialog .p-dialog-title {
  font-family: "kpmg-light";
  font-weight: 400 !important;
  font-size: 24px !important;
  letter-spacing: 0.7px;
}
.p-dialog .p-dialog-header {
  font-family: "kpmg-light";
  font-weight: 400 !important;
  font-size: 24px !important;
  letter-spacing: 0.7px;
  text-align: left ;
  display: block ;
  padding: 1.5rem 2rem 2rem;
}
.p-dialog .p-dialog-header .dialog-header-kpmg {
  display: flex ;
  align-items: flex-end;
}
.p-dialog .p-dialog-header .dialog-header-kpmg .dialog-title-kpmg {
  font-size: 18px ;
  margin-left: 1.8rem;
}
.p-dialog .p-dialog-header .dialog-header-kpmg .pi {
  font-size: 2.5rem;
}
.p-dialog .p-dialog-content {
  min-height: 50px ;
  padding: 0 2rem 2rem ;
  font-size: 16px;
  text-align: left ;
  display: block ;
}
.p-dialog .p-dialog-footer {
  border: 0 ;
  text-align: right ;
  padding: 0 2rem 2rem ;
}
.p-dialog .p-dialog-header-icons {
  position: absolute ;
  top: 10px;
  right: 10px;
  font-size: 50px ;
  padding-top: 0 ;
}
.p-confirm-dialog .p-dialog-header {
  text-align: left ;
  display: block ;
  padding: 1.5rem 2rem 2rem;
  border-bottom: unset ;
}
.p-confirm-dialog .p-dialog-header .p-dialog-title {
  font-size: 20px ;
  margin-left: 50px ;
  vertical-align: sub ;
}
.p-confirm-dialog .p-dialog-content {
  padding: 0 2rem 2rem ;
}
.p-confirm-dialog .p-dialog-footer {
  padding: 0 2rem 2rem ;
  text-align: left ;
}
.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0 ;
}
.p-confirm-dialog .p-confirm-dialog-icon {
  position: absolute ;
  margin: 1.5rem 2rem 2rem;
  top: 0 ;
  left: 0 ;
  font-size: 2.5rem;
  color: #fff;
}
.p-confirm-dialog .p-confirm-dialog-accept {
  transition: unset ;
  outline: none ;
}
.p-dropdown-item {
  white-space: normal !important;
}
p-dropdown .form-control {
  display: flex;
}
.p-treetable-header {
  border: none;
  background-color: transparent;
}
.p-treetable-thead > tr > th .p-inputtext {
  font-size: 14px !important;
  padding: 0.5em !important;
  height: 25px;
  width: 100%;
}
.p-treetable-thead > tr > th .p-calendar {
  border: none;
  font-size: 14px !important;
}
.p-treetable-thead > tr > th .p-multiselect {
  border: 1px solid #a6a6a6 !important;
  height: 25px !important;
  padding: 0;
  margin-top: 0px !important;
  width: 100% !important;
}
.p-treetable-thead > tr > th .p-multiselect .p-multiselect-label {
  margin-top: -4px !important;
  padding: 0.5em;
  font-size: 13px !important;
  min-width: auto !important;
  max-width: 278px !important;
}
