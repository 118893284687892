@login-primary: #001944;
@login-bg-secondary: #00295f;
@color-white: #fff;
@bg-border-dark: #032c72;

.modal {
    background-color: rgba(0, 0, 0, 0.5) !important;
}

select {
    height: 43px !important;
}

::ng-deep .p-datatable-scrollable-header-box {
    padding-right: 0 !important;
}

.modal-backdrop.show {
    opacity: 0 !important;
}

.modal-backdrop {
    z-index: 0 !important;
}

account {
    message-bar {
        .message-container {
            width: 380px;
        }
    }

    background: #001944;
    height: 100%;
}

@media (max-width: 768px) {
    .m-login.m-login--1 .m-login__aside .m-login__wrapper {
        background: @login-primary !important;
        color: @color-white !important;
        box-shadow: 1px 1px 1px 2px @login-primary;
    }
}

@media (max-width: 1500px) {
    .m-login.m-login--1 .m-login__aside .m-login__wrapper {
        background: @login-primary !important;
        color: @color-white !important;
        box-shadow: 1px 1px 1px 2px @login-primary;
    }
}

.m-login.m-login--1 .m-login__wrapper {
    background: @login-primary;
    width: 37rem !important;
}

.m-login__aside {
    background-color: @login-primary;
    color: @color-white;
}

.m-login__logo a img {
    &.dac6_logo {
        filter: none;
    }

    &.kpmg_logo {
        filter: brightness(0) invert(1);
    }
}

.root-background {
    background: @login-primary !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.login-text {
    color: @color-white !important;
}

tenant-change {
    .modal .modal-content .modal-body {
        background: @login-primary !important;
        font-size: 14px !important;
    }

    .modal .modal-content .modal-footer {
        background: @login-primary !important;
        border-top: 1px solid @bg-border-dark !important;
    }

    .modal .modal-content .modal-header {
        background: @login-bg-secondary !important;
        border-bottom: none !important;
        border-radius: 0px !important;
    }
}

.center {
    text-align: center;
}

.m-login__form-action {
    .login {
        background-color: #38a3f7 !important;
        border-color: #38a3f7 !important;
        width: 100px !important;
        padding: 10px !important;
    }

    .login:hover {
        background-color: #38a3f7 !important;
        border-color: #38a3f7 !important;
        width: 100px !important;
        padding: 10px !important;
    }
}

.check_size {
    height: 20px !important;
    width: 20px !important;
}

.check_centered {
    align-self: center !important;
}

tenantChangeModal {
    .close-button {
        background-color: #001944 !important;
        color: #38a3f7 !important;

        &:hover {
            background-color: #38a3f7 !important;
            color: #FFFFFF !important;
        }

        &:active {
            background-color: #38a3f7 !important;
            color: #FFFFFF !important;
        }

        &:focus {
            background-color: #38a3f7 !important;
            color: #FFFFFF !important;
        }
    }

    .save-button {
        background-color: #38a3f7 !important;
        border-color: #38a3f7 !important;
        width: 100px !important;
        width: auto !important;
    }

    .save-button:hover {
        background-color: #38a3f7 !important;
        border-color: #38a3f7 !important;
        width: 100px !important;
        width: auto !important;
    }
}

.forget-container {
    margin-top: -30px !important;

    a {
        font-size: 14px;
        color: #FFFFFF;
    }
}

.m-login.m-login--1 .m-login__wrapper .m-login__form {
    margin-top: 1rem !important;
}

.pi-sort-alt:before {
    content: "\e99e" !important;
}

.pi-sort-amount-up-alt:before {
    content: "\e9a2" !important;
}

.pi-sort-amount-down:before {
    content: "\e9a0" !important;
}

.primeng-datatable-container .primeng-paging-container .p-paginator .p-dropdown {
    min-width: 36px !important;
}

.kpmg-table-overview {
    background: initial !important;
}

.m-footer {
    padding: 3px 30px !important;
    height: 42px !important;
    min-height: 42px !important;
}

.permission-filter {
    margin-bottom: 0px !important;
}

message-bar {
    .message-container {
        .custom-carousel {
            .product-item {
                height: 100%;

                .product-item-content {
                    border-radius: 3px;
                    text-align: -webkit-center;
                    padding: 2rem 1rem;
                    max-height: 210px;
                    width: 70%;
                    overflow-wrap: break-word;
                    margin: 0 auto;

                    & .title {
                        text-align: center;
                    }

                    & .content {
                        height: 118px;
                        overflow: hidden;
                        display: flex;
                        width: 100%;
                        text-align: left;
                        justify-content: center;
                    }
                }

                ul {
                    list-style-position: inside;
                    padding-left: 0;
                }
            }

            .remove-container {
                text-align: end;
                margin-bottom: -29px;
                padding: 7px 10px 0px 0px;
                cursor: pointer;
            }
        }
    }

    .alert {
        max-height: 210px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .gmessages {
        .alert {
            & .alert-heading {
                text-align: center;
            }

            & span {
                ul, p {
                    width: 70%;
                    text-align: left;
                    max-height: 180px;
                    margin: 0 auto;
                    list-style-position: inside;
                    padding-left: 0;
                    height: auto;
                    overflow: hidden;
                    padding-bottom: 20px;
                }
            }
        }
    }

    .see-more {
        font-weight: bold;
        cursor: pointer;
    }

    .p-overlaypanel-content {
        left: unset !important;
        width: 380px;
        overflow-wrap: break-word;
        height: 250px;
        overflow-y: auto;
    }

    :not(.tooltip-overlay):not(.user-responsibility-overlay).p-overlaypanel {
        top: unset !important;
        left: unset !important;
    }

    :not(.tooltip-overlay):not(.user-responsibility-overlay).p-overlaypanel .p-overlaypanel-content {
        margin-right: -1.3rem !important;
        padding-right: 25px;
        color: white !important;
    }

    .p-overlaypanel-close {
        background: initial !important;
        color: #ffffff !important;
        border-radius: 0 !important;
        top: 0px !important;
        right: 0px !important;
    }

    .p-overlaypanel {
        margin-top: 0px !important;
    }
}

.p-slidemenu .p-menuitem-link.p-disabled {
    pointer-events: auto !important;
}

p-toast .p-toast {
    opacity: 0.97 !important;
}

inbox-component {
    .progress {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 15px;
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 9px;

        .progress-container {
            width: 210px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .p-progressbar {
                width: 160px;
            }

            .p-progressbar .p-progressbar-label {
                line-height: 21px !important;
            }
        }
    }

    .warning-label {
        position: absolute;
        top: 73px;
        left: 15%;
        margin: 15px;
        padding: 9px;
        font-weight: bold;
    }
}

.p-progressbar .p-progressbar-value {
    background-color: #0091da ! important;
}

.p-tooltip {
    max-width: 600px;
    overflow-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    color: white;

    & span {
        color: white !important;
    }

    & a {
        color: white !important;
        font-weight: bold;
    }
}

.increase-modal {
    input, select {
        border: 1px solid #ced4da !important;
    }
}

host-background-jobs {
    .form-control {
        height: 30px;
        margin-top: 6px;
        margin-left: 2px;
    }
}
.form-control {
    &.p-dropdown{
        padding-right: 0 !important;

        & .p-dropdown-trigger{
            align-items: normal;
        }
    }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
    .p-progress-spinner {
        left: 50% !important;
        position: absolute !important;
        top: 36% !important;
    }
}

@media screen and (min-device-width: 1600px) {
    .p-progress-spinner {
        left: 50% !important;
        position: absolute !important;
        top: 30% !important;
    }
}

.pinput-style {
    .p-inputtext {
        display: block !important;
        width: 100% !important;
        height: calc(2.95rem + 2px) !important;
        padding: .85rem 1.15rem !important;
        font-size: 16px !important;
        line-height: 1.25 !important;
        color: #575962 !important;
        background-color: #fff !important;
        background-clip: padding-box !important;
        border: 1px solid #ebedf2 !important;
        border-radius: .25rem !important;
    }

    .p-inputtext:enabled:focus {
        border-color: #00338d !important;
        box-shadow: 0 0 0 0 #00338d !important;
    }
}

.pdropDown-style {
    .p-inputtext {
        font-size: 14px !important;
        background-color: #fff !important;
        border: 0 !important;
        font-family: "Arial", sans-serif !important;
    }
}

.p-treetable-scrollable-body {
    overflow-y: auto !important;
}

.hidePanel {
    display: none;
}

.p-menuitem-text {
    font-weight: normal !important;
    font-size: 1rem;
}

.PlausibilityBuilding {
    display: none;
}

mergeWithExcel {
    .col-6 {
        font-size: 15px;
    }
}

.red {
    color: red !important;
}

.p-calendar{
    min-width: 100%;
}
.filter-none{
    filter: none !important;
}
