/* roboto-300 - vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-300.eot'); /* IE9 Compat Modes */
    src: local('Roboto Light'), local('Roboto-Light'), url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-300.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-300.woff') format('woff'), /* Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-300.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-300.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-regular - vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-regular.eot'); /* IE9 Compat Modes */
    src: local('Roboto'), local('Roboto-Regular'), url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-regular.woff') format('woff'), /* Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-500.eot'); /* IE9 Compat Modes */
    src: local('Roboto Medium'), local('Roboto-Medium'), url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-500.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-500.woff') format('woff'), /* Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-500.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-700.eot'); /* IE9 Compat Modes */
    src: local('Roboto Bold'), local('Roboto-Bold'), url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-700.woff2') format('woff2'), /* Super Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-700.woff') format('woff'), /* Modern Browsers */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-700.ttf') format('truetype'), /* Safari, Android, iOS */ url('./Roboto/roboto-v18-vietnamese_latin-ext_latin_greek_cyrillic-ext_greek-ext_cyrillic-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
