.p-fileupload .p-corner-top {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.p-fileupload .p-corner-bottom {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.p-fileupload .p-button {
    border-radius: 2px;
}
