@font-face {
    font-family: 'kpmg-light';
    font-style: normal;
    font-weight: 300;
    src: url('./kpmg/KPMG-Light.eot');
    src: local('kpmg-light'), local('kpmg-light'),
    url('./kpmg/KPMG-Light.eot?#iefix') format('embedded-opentype'),
    url('./kpmg/KPMG-Light.woff2') format('woff2'),
    url('./kpmg/KPMG-Light.woff') format('woff'),
    url('./kpmg/KPMG-Light.ttf') format('truetype');
}
